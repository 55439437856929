<template>
  <div
    v-show="isShown"
    class="field-dropdown w-full cursor-pointer"
  >
    <div
      class="field-dropdown__header flex items-center font-normal text-sm px-2"
      :class="{
        'bg-primary-300': field.id === selectedFieldId,
      }"
      :style="{'padding-left': `${8 + level*20}px`}"
      @click="selectField"
    >
      <!-- <div
        v-if="field.is_options"
        class="p-1 cursor-pointer text-primary hover:text-primary-focus"
      >
        <font-awesome-icon
          icon="chevron-down"
          class="field-dropdown__icon fill-current transition-all"
          :class="{'field-dropdown__icon--expanded': expanded}"
          @click.stop="toggleOption"
        />
      </div> -->
      <span class="p-1.5">
        {{ getFieldLabel(field) }}
      </span>
      <div
        v-if="field.is_cascaded"
        class="p-1 cursor-pointer"
        @click.stop="toggleOption"
      >
        <CascadedIcon class="w-3.5 text-primary fill-current" />
      </div>
      <div
        v-else-if="field.is_options"
        class="p-1 cursor-pointer"
        @click.stop="toggleOption"
      >
        <OptionsIcon class="w-3.5 text-primary fill-current" />
      </div>
      <!-- <OptionsIcon v-else-if="field.is_options" class="w-3.5" /> -->
    </div>
    <div
      v-show="expanded"
      class="field-dropdown__dependents flex flex-col items-center"
    >
      <div
        class="p-2"
        v-if="isLoading"
      >
        <Loader :loading="true" :size="5" />
      </div>
      <template v-else-if="fieldData">
        <OptionDropdown
          ref="option"
          class="field-dropdown__option border-gray-200"
          v-for="option in fieldData.data.field_options"
          :key="option.id"
          :option="option"
          :field="field"
          :selectedFieldId="selectedFieldId"
          :getDependentsMethod="getDependentsMethod"
          :getOptionsMethod="getOptionsMethod"
          :sublist="true"
          :level="level + 1"
          :searchString="searchString"
          v-on="$listeners"
        />
      </template>
    </div>
  </div>
</template>

<script>
import Loader from "@shared/loader";
import { checkShow } from './utils';
import OptionsIcon from '@shared/assets/icons/field-options.svg';
import CascadedIcon from '@shared/assets/icons/field-cascaded.svg';

const OptionDropdown = () => import("./option-dropdown.vue");

export default {
  name: 'field-dropdown',
  props: {
    field: {
      type: Object,
      required: true,
    },
    selectedFieldId: {
      type: String,
    },
    getDependentsMethod: {
      type: Function,
    },
    getOptionsMethod: {
      type: Function,
    },
    level: {
      type: Number,
      default: 0,
    },
    searchString: {
      type: String,
      default: '',
    },
  },
  components: {
    Loader,
    OptionsIcon,
    OptionDropdown,
    CascadedIcon,
  },
  data() {
    return {
      expanded: false,
      isLoading: false,
      isLoaded: false,
      fieldData: null,
    }
  },
  computed: {
    isShown() {
      return checkShow(this.searchString, this.field);
    },
  },
  methods: {
    async toggleOption() {
      if (this.isLoading) return;
      this.expanded = !this.expanded;
      if (!this.isLoaded) {
        this.isLoading = true;
        try {
          this.fieldData = await this.getOptionsMethod(this.field.id);
          this.$set(this.field, 'field_options', this.fieldData.data.field_options);
          this.isLoaded = true;
        } catch (error) {
          console.log(error);
          this.expanded = !this.expanded;
        }
      }
      this.isLoading = false;
    },
    getFieldLabel(field) {
      return ['Candidate Consent','Candidate Optional Consent'].includes(field.field_type) ?
        field.field_type.replace('Candidate','') :
        field.field_label;
    },
    selectField() {
      this.$emit('select:field', {
        field: this.field,
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.field-dropdown {
  &__icon {
    &--expanded {
      transform: rotate(180deg)
    }
  }
  &__header {
    &:hover {
      background-color: #D6E9FF;
    }
  }
}
</style>